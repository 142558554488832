const styles = (theme) => ({
  outer: {
    position: "relative",
    "& .homehero1": {
      position: "absolute",
      right: 0,
      top: "15%",
      [theme.breakpoints.down("700")]: {
        right: "0px",
      },
    },
    "& .homehero2": {
      position: "absolute",
      right: "10%",
      bottom: "10%",
      [theme.breakpoints.down("1250")]: {
        right: "0%",
        bottom: "-20%",
      },
      [theme.breakpoints.down("700")]: {
        right: "0px",
        bottom: "-28%",
      },
    },
    "& .homehero3": {
      position: "absolute",
      right: "20%",
      bottom: "5%",
      width: 200,
      height: 200,
      borderRadius: "50%",
      "& img": {
        width: 200,
        height: 200,
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid #fff",
        boxShadow: "0 30px 60px 0 rgba(119, 133, 169, 0.28)",
      },
      [theme.breakpoints.down("980")]: {
        width: 160,
        height: 160,
        right: "5%",
        "& img": {
          width: 160,
          height: 160,
        },
      },
      [theme.breakpoints.down("700")]: {
        width: 80,
        height: 80,
        right: "0",
        bottom: 0,
        "& img": {
          width: 80,
          height: 80,
        },
      },
    },
    "& .homehero4": {
      right: "20%",
      width: 150,
      height: 150,
      top: "5%",
      position: "absolute",
      borderRadius: "50%",
      "& img": {
        width: 150,
        height: 150,
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid #fff",
        boxShadow: "0 30px 60px 0 rgba(119, 133, 169, 0.28)",
      },
      [theme.breakpoints.down("980")]: {
        width: 120,
        height: 120,
        "& img": {
          width: 120,
          height: 120,
        },
      },
      [theme.breakpoints.down("610")]: {
        width: 80,
        height: 80,
        top: "0%",
        "& img": {
          width: 80,
          height: 80,
        },
      },
    },
    "& .homehero5": {
      right: "50%",
      top: "5%",
      position: "absolute",
      objectFit: "cover",
      [theme.breakpoints.down("1250")]: {
        right: "40%",
      },
      [theme.breakpoints.down("740")]: {
        right: "30%",
      },
      [theme.breakpoints.down("610")]: {
        top: "9%",
      },
    },
    "& .homehero6": {
      top: "5%",
      left: "25%",
      width: 150,
      height: 150,
      position: "absolute",
      borderRadius: "50%",
      "& img": {
        width: 150,
        height: 150,
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid #fff",
        boxShadow: "0 30px 60px 0 rgba(119, 133, 169, 0.28)",
      },
      [theme.breakpoints.down("980")]: {
        width: 120,
        height: 120,
        "& img": {
          width: 120,
          height: 120,
        },
      },
      [theme.breakpoints.down("610")]: {
        width: 80,
        height: 80,
        top: "0%",
        "& img": {
          width: 80,
          height: 80,
        },
      },
    },
    "& .homehero7": {
      left: "20%",
      width: 100,
      height: 100,
      bottom: "5%",
      position: "absolute",
      borderRadius: "50%",
      "& img": {
        width: 100,
        height: 100,
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid #fff",
        boxShadow: "0 30px 60px 0 rgba(119, 133, 169, 0.28)",
      },
      [theme.breakpoints.down("1250")]: {
        left: "40%",
      },
      [theme.breakpoints.down("980")]: {
        width: 80,
        height: 80,
        bottom: "-10%",
        "& img": {
          width: 80,
          height: 80,
        },
      },
      [theme.breakpoints.down("480")]: {
        bottom: "-7%",
      },
    },
    "& .homehero8": {
      left: "15%",
      bottom: "10%",
      position: "absolute",
      objectFit: "cover",
      [theme.breakpoints.down("1250")]: {
        left: "20%",
      },
      [theme.breakpoints.down("520")]: {
        left: "0%",
      },
    },
    "& .homehero9": {
      top: "20%",
      left: "10%",
      width: 100,
      height: 100,
      position: "absolute",
      borderRadius: "50%",
      "& img": {
        width: 100,
        height: 100,
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid #fff",
        boxShadow: "0 30px 60px 0 rgba(119, 133, 169, 0.28)",
      },
      [theme.breakpoints.down("980")]: {
        width: 80,
        height: 80,
        top: "10%",
        "& img": {
          width: 80,
          height: 80,
        },
      },
      [theme.breakpoints.down("610")]: {
        width: 60,
        height: 60,
        left: "0%",
        "& img": {
          width: 60,
          height: 60,
        },
      },
    },
    "& .homehero10": {
      position: "absolute",
      bottom: "10%",
      width: 200,
      height: 200,
      left: "-2%",
      borderRadius: "50%",
      "& img": {
        width: 200,
        height: 200,
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid #fff",
        boxShadow: "0 30px 60px 0 rgba(119, 133, 169, 0.28)",
      },
      [theme.breakpoints.down("980")]: {
        width: 160,
        height: 160,
        "& img": {
          width: 160,
          height: 160,
        },
      },
      [theme.breakpoints.down("520")]: {
        width: 80,
        height: 80,
        bottom: "0",
        "& img": {
          width: 80,
          height: 80,
        },
      },
    },
  },
  aboutHero: {
    zIndex: 10,
    position: "relative",
    height: 750,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(10),
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      paddingTop: theme.spacing(20),
    },
  },
  aboutHeroOverlay: {
    zIndex: 0,
    top: 0,
    position: "absolute",
    backgroundPosition: "top",
    backgroundSize: "cover",
    height: 900,
    width: "100%",
  },
  heroTitle: {
    color: "#4c4c78",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },
  heroSubTitle: {
    color: "#4c4c78",
    textAlign: "center",
    fontWeight: 900,
  },
  heroButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& button": {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column;",
      alignItems: "center",
    },
  },
});

export default styles;
