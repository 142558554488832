import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { Grid, Snackbar } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import style from "./style";
import { useLayout } from "../../utils/layout-context";
import {
  imagePropTypes,
  structuredTextPropTypes,
} from "../../utils/prop-types";
import { useRouter } from "next/router";
import CheckRounded from "@material-ui/icons/CheckRounded";
import WarningOutlined from "@material-ui/icons/WarningOutlined";
import ErrorRounded from "@material-ui/icons/ErrorRounded";
import appConstants from "../../utils/constants/vfi-constants";
import MuiAlert from "@material-ui/lab/Alert";
import Image from "next/future/image";
import { motion } from "framer-motion";
import {
  vBounce,
  vSlideRightFadeCustom,
  vTextFade,
} from "../../utils/animations";

const Subscribe = ({ slice, classes }) => {
  const { primary } = slice;
  console.log("primary", primary);
  const { layout } = useLayout();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const router = useRouter();
  const locale = router.locale;
  const send = async (email) => {
    if (emailValid) {
      const result = await fetch("api/contact/mailing-list", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, twoLetterLanguageCode: locale }),
      });

      if (result.ok) {
        setClearInput(!clearInput);
        setSuccess(true);
        setError(false);

        router.push(primary.thankyouPageLink.uid);
      } else {
        setSuccess(false);
        setError(true);
      }
    } else {
      setSnackbarOpen(true);
      setSuccess(false);
      setError(false);
    }
  };

  const emailValidation = (e) => {
    setEmailValid(appConstants.emailRegex.test(e.target.value));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setSnackbarOpen(false);
    setError(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.subscribe}>
      <Container maxWidth="lg">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            {primary.resourceImage?.url && (
              <motion.div
                variants={vBounce}
                initial={"offscreen"}
                animate={"onscreen"}
                viewport={viewport}
              >
                <Image
                  src={primary.resourceImage.url}
                  alt={primary.resourceImage.alt || ""}
                  className="resourceImage"
                  width={primary.resourceImage.dimensions.width}
                  height={primary.resourceImage.dimensions.height}
                  sizes={"100vw"}
                />
              </motion.div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              variants={vTextFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
              transition={{
                type: "tween",
                delay: 0.5,
                duration: 0.8,
              }}
            >
              <Typography variant="h3" className="title">
                {RichText.asText(primary.title)}
              </Typography>
            </motion.div>
            <motion.div
              variants={vTextFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
              transition={{
                type: "tween",
                delay: 0.8,
                duration: 0.8,
              }}
            >
              <Typography variant="subtitle2" className="description">
                {RichText.asText(primary.description)}
              </Typography>
            </motion.div>
            <motion.div
              variants={vSlideRightFadeCustom}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
              transition={{
                type: "tween",
                delay: 0.8,
                duration: 0.8,
              }}
            >
              <form
                onSubmit={(formData) => {
                  formData.preventDefault();
                  send(email);
                }}
              >
                <Paper className="subscribeWrapper">
                  <InputBase
                    className="input"
                    key={clearInput}
                    placeholder={RichText.asText(primary.emailPlaceholder)}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      emailValidation(e);
                    }}
                    type="email"
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    className="subButton"
                    size="large"
                    aria-label="directions"
                    type="submit"
                  >
                    {RichText.asText(primary.subscribeButtonText)}
                  </Button>
                </Paper>
              </form>
            </motion.div>
            <div className="privacyText">
              <motion.div
                variants={vTextFade}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={viewport}
                transition={{
                  type: "tween",
                  delay: 1,
                  duration: 0.8,
                }}
              >
                {RichText.render(primary.privacyText)}
              </motion.div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        className={classes.snackbarCut}
        open={success}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          icon={<CheckRounded fontSize="inherit" />}
          onClose={handleClose}
          severity="success"
        >
          {RichText.asText(layout.subscribe_success_message)}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen && !success && !error}
        autoHideDuration={6000}
        onClose={handleClose}
        className={classes.snackbarCut}
      >
        <Alert
          icon={<WarningOutlined fontSize="inherit" />}
          onClose={handleClose}
          severity="warning"
        >
          {RichText.asText(layout.subscribe_email_error_message)}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleClose}
        className={classes.snackbarCut}
      >
        <Alert
          icon={<ErrorRounded fontSize="inherit" />}
          onClose={handleClose}
          severity="error"
        >
          {RichText.asText(layout.subscribe_error_message)}
        </Alert>
      </Snackbar>
    </div>
  );
};

Subscribe.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      resourceImage: imagePropTypes,
      title: structuredTextPropTypes,
      description: structuredTextPropTypes,
      emailPlaceholder: structuredTextPropTypes,
      subscribeButtonText: structuredTextPropTypes,
      privacyText: structuredTextPropTypes,
      thankyouPageLink: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(Subscribe);
