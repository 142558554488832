import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import CustomLink from "../../components/CustomLink";
import Image from "next/future/image";
import { motion } from "framer-motion";
import { vPulseIn, vScaleFade } from "../../utils/animations";

const Partners = ({ slice, classes }) => {
  const { primary, items } = slice;

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <Container maxWidth="lg" className={classes.partners}>
      <TitleSubtitle
        title={RichText.asText(primary.title)}
        subtitle={RichText.asText(primary.subtitle)}
      />
      <div className="partnerLogo">
        {items.map((item, index) => (
          <motion.div
            key={index}
            variants={vPulseIn}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={viewport}
          >
            {item.partnerLogo?.url && (
              <Image
                src={item.partnerLogo.url}
                alt={item.partnerLogo.alt || ""}
                width={item.partnerLogo.dimensions.width}
                height={item.partnerLogo.dimensions.height}
                className={classes.testimonialImg}
              />
            )}
          </motion.div>
        ))}
      </div>
      <CustomLink link={primary.ctaButtonLink}>
        <motion.div
          variants={vScaleFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
        >
          <Button variant="contained" color="primary" className="cta">
            {RichText.asText(primary.ctaButtonText)}
          </Button>
        </motion.div>
      </CustomLink>
    </Container>
  );
};

Partners.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      ctaButtonText: structuredTextPropTypes,
      ctaButtonLink: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        partnerLogo: imagePropTypes,
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(Partners);
