import pattern from "../../public/patternSimpleText.svg";

const styles = (theme) => ({
  endorsements: {
    backgroundColor: "#F4F5F6",
    backgroundImage: `url(${pattern.src})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundBlendMode: "color-burn",
    backgroundRepeat: "no-repeat",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    "& .element": {
      position: "absolute",
      top: 80,
    },
    "& .endorsmentWrapperContainer": {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(9),
        paddingBottom: theme.spacing(9),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
    },
  },
  testimonialCardGrid: {
    "& .flexEnd": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  testimonialCard: {
    borderRadius: 6,
    boxShadow: "0 20px 30px 0 rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    "& .name": {
      color: "#4c4c78",
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.79,
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
      },
    },
    "& .testimonialTitle": {
      color: "#4c4c78",
      fontWeight: 500,
      maxWidth: 200,
    },
    "& .testimonial": {
      color: "#4c4c78",
      fontWeight: 700,
      lineHeight: 1.79,
      fontFamily: "Raleway,Assistant",
      fontStyle: "italic",
      flex: 1,
    },
  },
  testimonialImg: {
    width: 113,
    height: 113,
    minWidth: 113,
    minHeight: 113,
    maxWidth: 113,
    maxHeight: 113,
    borderRadius: 100,
    background: "#fff",
    boxShadow: "0 38px 66px 0 rgba(80, 82, 138, 0.18)",
    border: "solid 2px #ffffff",
    marginTop: "-60px",
    marginRight: "-24px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      marginRight: "-20px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-10px",
    },
  },
});

export default styles;
