import React from "react";
import PropTypes from "prop-types";
import { RichText, Date } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import PersonItem from "../../components/PersonItem";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useAdditionalData } from "../../utils/additional-data-context";
import CustomLink from "../../components/CustomLink";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import {
  vPulseIn,
  vSlideRightFadeCustom,
  vTextFade,
} from "../../utils/animations";

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const FeaturedBlogs = ({ slice, classes }) => {
  const { primary, items } = slice;
  const additionalData = useAdditionalData();
  const viewport = {
    once: true,
    margin: "-6%",
  };
  return (
    <div className={classes.featuredBlogs}>
      <Container maxWidth="lg">
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.5,
            duration: 0.8,
          }}
        >
          <TitleSubtitle
            title={RichText.asText(primary.title)}
            subtitle={RichText.asText(primary.subtitle)}
            showClass
          />
        </motion.div>
        {primary.showLatestBlogs && additionalData.blogs && (
          <Grid
            container
            spacing={4}
            direction="row"
            justify="center"
            className="featureBlogsContainer"
          >
            {additionalData.blogs.results.map((blog, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <motion.div
                    variants={vSlideRightFadeCustom}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={viewport}
                    transition={{
                      type: "tween",
                      delay: index * 0.2,
                      duration: 0.8,
                    }}
                    style={{
                      height: "100%",
                    }}
                  >
                    <PersonItem
                      showTag={
                        blog.data.blog_topics &&
                        blog.data.blog_topics.length > 0
                      }
                      link={blog}
                      tag={
                        blog.data.blog_topics &&
                        blog.data.blog_topics.length > 0 ? (
                          <CustomLink
                            link={blog.data.blog_topics[0].blog_topic}
                          >
                            {blog.data.blog_topics[0].blog_topic.uid}
                          </CustomLink>
                        ) : null
                      }
                      bannerImageSrc={blog.data.featured_image.url}
                      bannerImageAlt={blog.data.featured_image.alt}
                      name={RichText.asText(blog.data.title)}
                      title={Date(blog.data.date).toLocaleDateString(
                        useRouter().locale,
                        dateOptions
                      )}
                    />
                  </motion.div>
                </Grid>
              );
            })}
          </Grid>
        )}
        {!primary.showLatestBlogs && (
          <Grid
            container
            spacing={4}
            direction="row"
            justify="center"
            className="featureBlogsContainer"
          >
            {items &&
              items.map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    {item.blogPost && item.blogPost.data && (
                      <CustomLink link={item.blogPost}>
                        <motion.div
                          variants={vSlideRightFadeCustom}
                          initial={"offscreen"}
                          whileInView={"onscreen"}
                          viewport={viewport}
                          transition={{
                            type: "tween",
                            delay: index * 0.2,
                            duration: 0.8,
                          }}
                          style={{
                            height: "100%",
                          }}
                        >
                          <PersonItem
                            showTag={
                              item.blogPost.data.blog_topics &&
                              item.blogPost.data.blog_topics.length > 0
                            }
                            bannerImageSrc={
                              item.blogPost.data.featured_image.url
                            }
                            bannerImageAlt={
                              item.blogPost.data.featured_image.alt
                            }
                            name={RichText.asText(item.blogPost.data.title)}
                            title={Date(
                              item.blogPost.data.date
                            ).toLocaleDateString(
                              useRouter().locale,
                              dateOptions
                            )}
                            tag={
                              item.blogPost.data.blog_topics &&
                              item.blogPost.data.blog_topics.length > 0 ? (
                                <CustomLink
                                  link={
                                    item.blogPost.data.blog_topics[0].blog_topic
                                  }
                                >
                                  {
                                    item.blogPost.data.blog_topics[0].blog_topic
                                      .uid
                                  }
                                </CustomLink>
                              ) : null
                            }
                          />
                        </motion.div>
                      </CustomLink>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        )}
        <Grid container direction="row" justify="center" alignItems="center">
          <CustomLink link={primary.ctaButtonLink}>
            <motion.div
              variants={vPulseIn}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              <Button variant="outlined">
                {RichText.asText(primary.ctaButtonText)}
              </Button>
            </motion.div>
          </CustomLink>
        </Grid>
      </Container>
    </div>
  );
};

FeaturedBlogs.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      ctaButtonText: structuredTextPropTypes,
      ctaButtonLink: PropTypes.objectOf(PropTypes.any),
      showLatestBlogs: PropTypes.bool,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        blogPost: PropTypes.objectOf(PropTypes.any),
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(FeaturedBlogs);
