import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import style from "./style";
import CustomLink from "../CustomLink";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import { motion } from "framer-motion";
import { vBounce, vTextFade } from "../../utils/animations";
import Image from "next/future/image";

const LeftTextImageRight = ({
  classes,
  preTitle,
  title,
  description,
  btnLabel,
  rightImage,
  btnUrl,
  videoLink,
  openVideoDialog,
  rightBgImageVisibility,
}) => {
  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <Container maxWidth="lg" className={classes.leftTextImageRight}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <motion.div
            variants={vTextFade}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={viewport}
            transition={{
              type: "tween",
              duration: 0.8,
            }}
          >
            <Typography variant="subtitle2" className="preTitle">
              {preTitle}
            </Typography>
          </motion.div>
          <motion.div
            variants={vTextFade}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={viewport}
            transition={{
              type: "tween",
              delay: 0.2,
              duration: 0.8,
            }}
          >
            <Typography variant="h1" component="h2" className="title">
              {title}
            </Typography>
          </motion.div>
          <motion.div
            variants={vTextFade}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={viewport}
            transition={{
              type: "tween",
              delay: 0.4,
              duration: 0.8,
            }}
          >
            <Typography variant="subtitle1" className="description">
              {description}
            </Typography>
          </motion.div>
          {btnUrl && btnLabel && (
            <CustomLink className={""} link={btnUrl} key={btnLabel}>
              <motion.div
                variants={vTextFade}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={viewport}
                transition={{
                  type: "tween",
                  delay: 0.6,
                  duration: 0.8,
                }}
              >
                <Button variant="contained" color="primary">
                  {btnLabel}
                </Button>
              </motion.div>
            </CustomLink>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="rightImageWrapper"
          container
          direction="row"
          justify="center"
        >
          {rightBgImageVisibility && <div className="rightImageOverlay" />}
          {rightImage?.url && (
            <motion.div
              variants={vBounce}
              initial={"offscreen"}
              animate={"onscreen"}
              viewport={viewport}
              style={{
                zIndex: 10,
              }}
            >
              <Image
                src={rightImage.url}
                className={clsx("rightImage", {
                  [classes.rightBgImageVisibility]: !rightBgImageVisibility,
                })}
                alt={rightImage.alt || ""}
                width={rightImage.dimensions.width}
                height={rightImage.dimensions.height}
              />
            </motion.div>
          )}
          {videoLink && (
            <ButtonBase
              onClick={() => openVideoDialog()}
              className="playBtn"
            ></ButtonBase>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

LeftTextImageRight.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  preTitle: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.objectOf(PropTypes.string).isRequired,
  description: PropTypes.objectOf(PropTypes.string).isRequired,
  btnLabel: PropTypes.objectOf(PropTypes.string).isRequired,
  rightBgImageVisibility: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default withStyles(style)(LeftTextImageRight);
