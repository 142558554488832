import React, { useState } from "react";
import style from "./style.js";
import { withStyles, Grid } from "@material-ui/core";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { useRouter } from "next/router";
import FacebookIcon from "mdi-react/FacebookIcon";
import TwitterIcon from "mdi-react/TwitterIcon";
import LinkedinIcon from "mdi-react/LinkedinIcon";
import MailIcon from "mdi-react/MailOutlineIcon";
import { motion } from "framer-motion";
import CustomShareButton from "../share-button";

const NewGrid = motion(Grid);

const CustomShareComponent = (props) => {
  const { classes, onClick } = props;
  const [share, setShare] = useState(false);

  const openShare = () => {
    setShare(true);
  };
  const closeShare = () => {
    setShare(false);
  };
  const router = useRouter();
  const sharingVariants = {
    open: {
      transition: {
        staggerDirection: -1,
        staggerChildren: 0.1,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    closed: {
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
    open: {
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  };

  return (
    <div className={classes.customShare}>
      <NewGrid
        container
        variants={sharingVariants}
        animate={share ? "open" : "closed"}
        initial="closed"
        onMouseEnter={() => openShare()}
        onMouseLeave={() => closeShare()}
      >
        <NewGrid item variants={item}>
          <EmailShareButton
            className={classes.socialButton}
            url={process.env.SITE_URL + "/" + router.locale + router.asPath}
          >
            <MailIcon />
          </EmailShareButton>
        </NewGrid>
        <NewGrid item variants={item}>
          <FacebookShareButton
            className={classes.socialButton}
            url={process.env.SITE_URL + "/" + router.locale + router.asPath}
          >
            <FacebookIcon />
          </FacebookShareButton>
        </NewGrid>
        <NewGrid item variants={item}>
          <TwitterShareButton
            className={classes.socialButton}
            url={process.env.SITE_URL + "/" + router.locale + router.asPath}
          >
            <TwitterIcon />
          </TwitterShareButton>
        </NewGrid>
        <NewGrid item variants={item}>
          <LinkedinShareButton
            className={classes.socialButton}
            url={process.env.SITE_URL + "/" + router.locale + router.asPath}
          >
            <LinkedinIcon />
          </LinkedinShareButton>
        </NewGrid>
      </NewGrid>
      <div
        className={classes.shareButton}
        onMouseEnter={() => openShare()}
        onMouseLeave={() => closeShare()}
      >
        <CustomShareButton />
      </div>
    </div>
  );
};

export default withStyles(style)(CustomShareComponent);
