import pattern from "../../public/patternSimpleText.svg";
import playIcon from "../../public/playBtnBg.png";

const styles = (theme) => ({
  textWithVideoBg: {
    clipPath: "polygon(0 0, 100% 15%, 100% 100%, 0 100%)",
    position: "relative",
    background: "#8BC249",
    backgroundImage: `url(${pattern.src})`,
    backgroundBlendMode: "exclusion",
    backgroundSize: "123% 134%",
    backgroundPosition: "100%",
    backgroundRepeat: "no-repeat",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(8),
    },
    "& .playBtn": {
      height: 69,
      width: 105,
      backgroundImage: `url(${playIcon.src})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      borderRadius: 8,
      boxShadow: "0px 2px 6px 0px #1f1f1f",
      top: "50%",
      left: "50%",
      right: "50%",
      cursor: "pointer",
      transform:
        theme.direction === "ltr"
          ? "translateY(-70%) translateX(-50%)"
          : "translateY(-70%) translateX(50%)",
      [theme.breakpoints.down("844")]: {
        height: 60,
        width: 90,
      },
      [theme.breakpoints.down("xs")]: {
        height: 50,
        width: 80,
        borderRadius: 8,
      },
    },
    "&::after": {
      clipPath: "polygon(0 0, 100% 15%, 100% 100%, 0 85%)",
      background:
        "linear-gradient(90deg, rgba(74,146,207,1) 30%, rgb(105 144 57) 30%,rgba(140,194,75,1) 100% )",
      content: '""',
      position: "absolute",
      left: 0,
      top: "-84.5%",
      right: 0,
      height: "100%",
      display: "block",
      zIndex: 9,
    },
    "& button": {
      backgroundColor: "transparent",
      border: "1px solid #fff",
    },
  },
});

export default styles;
