import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import LeftTextImageRight from "../../components/LeftTextImageRight";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import { Element } from "react-scroll";
const TextWithImage = ({ slice, classes }) => {
  const { primary } = slice;

  return (
    <div className={classes.textWithImageBg}>
      <Element name={primary.anchorId} className="element"></Element>
      <LeftTextImageRight
        preTitle={RichText.asText(primary.preTitle)}
        title={RichText.asText(primary.title)}
        description={RichText.asText(primary.description)}
        btnLabel={primary.ctaButtonText}
        btnUrl={primary.ctaButtonLink}
        rightImage={primary.rightImage}
        rightBgImageVisibility={primary.rightBgImageVisibility}
      />
    </div>
  );
};

TextWithImage.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      pre_title: structuredTextPropTypes,
      title: structuredTextPropTypes,
      description: structuredTextPropTypes,
      video_thumbnail: imagePropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(TextWithImage);
