import analyticsService from "../../../services/analytics-service";
import braintreeClientService from "../../../services/braintree/shared/braintree-client-service";
import BraintreeConfig from "../../../services/braintree/shared/braintree-config";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IPaymentFormInfo, {
  IPaymentUIEvents,
} from "../../../models/braintree/IPaymentFormInfo";
import Logger from "../../../services/error-logging-service";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import style from "./style";
import Typography from "@material-ui/core/Typography";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { useRouter } from "next/router";
import {
  faApplePay,
  faCcVisa,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcMastercard,
  faCcJcb,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLayout } from "../../../utils/layout-context";
import { useVFIStoreState } from "../../../store/vfi-easy-peasy-store";
import { withStyles } from "@material-ui/core/styles";
import {
  LocalPaymentProviders,
  PaymentErrorType,
} from "../../../models/braintree/DonationModels";
import RecaptchaErrorPopup from "../RecaptchaErrorPopup";
import { Alert } from "@material-ui/lab";
import MobilePopupWarningPopup from "../MobilePopupWarningPopup";
import { useMediaQueries } from "@react-hook/media-query";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

interface IPayNowPopupProps {
  classes: any;
  popupOpen: boolean;
  closePopup: () => void;
  getPaymentInfo: () => IPaymentFormInfo;
  recurringDonation: boolean;
  coverTransactionCosts: boolean;
  netAmount: number;
  goBackToAddressPopup: () => void;
}

const PayNowPopup = ({
  classes,
  popupOpen,
  closePopup,
  getPaymentInfo,
  recurringDonation,
  netAmount,
  coverTransactionCosts,
  goBackToAddressPopup,
}: IPayNowPopupProps) => {
  const locale = useRouter().locale;
  const layout = useLayout().layout;
  const state = useVFIStoreState((state) => state);
  const creditCardFormRef = useRef(null);
  const venmoButtonRef = useRef(null);
  const klarnaButtonRef = useRef(null);
  const iDealButtonRef = useRef(null);
  const applePayButtonRef = useRef(null);
  const payPalButtonRef = useRef(null);
  const googlePayButtonRef = useRef(null);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(
    false
  );
  const [
    showMobilePopupWarning,
    setShowMobilePopupWarning,
  ] = useState<boolean>();
  const [handleMobilePopupContinue, setHandleMobilePopupContinue] = useState<
    () => Promise<void>
  >();
  const [creditCardType, setCreditCardType] = useState<IconDefinition>(null);
  const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);
  const [creditCardPaymentErrors, setCreditCardPaymentErrors] = useState<
    string[]
  >();
  const [verificationUrl, setVerificiationUrl] = useState<string>();
  const [
    paymentFormInitComplete,
    setPaymentFormInitComplete,
  ] = useState<boolean>(false);
  const [paypalErrors, setPaypalErrors] = useState<string[]>();
  const [
    showRecaptchaErrorPopup,
    setShowRecaptchaErrorPopup,
  ] = useState<boolean>(false);

  const [showApplePay, setShowApplePay] = useState<boolean>(false);
  const [showVenmo, setShowVenmo] = useState<boolean>(false);
  const [showPaypal, setShowPaypal] = useState<boolean>(false);
  const [showKlarna, setShowKlarna] = useState<boolean>(false);
  const [showIdeal, setShowIdeal] = useState<boolean>(false);
  const [recaptchaErrorCodes, setRecaptchaErrorCodes] = useState<string[]>();

  //credit card state
  const [cardNumberErrorText, setCardNumberErrorText] = useState<string>();
  const [cvvErrorText, setCvvErrorText] = useState<string>();
  const [
    expirationDateErrorText,
    setExpirationDateErrorText,
  ] = useState<string>();
  const [
    cardholderNameErrorText,
    setCardholderNameErrorText,
  ] = useState<string>();

  // catch mobile-devices
  const { matches, matchesAny, matchesAll } = useMediaQueries({
    screen: "screen",
    width: "(max-width: 600px)",
  });

  useEffect(() => {
    return () => {
      resetPaypal();
    };
  }, []);

  const resetPaypal = () => {
    Object.keys(window).forEach((key) => {
      if (/paypal|zoid|post_robot/.test(key)) {
        console.log("DELETING PAYPAL SDK ON UNMOUNT");
        delete window[key];
      }
    });
    document
      .querySelectorAll('script[src*="www.paypal.com/sdk"]')
      .forEach((node) => node.remove());
  };

  const payLocalMaybeOpenMobileWarningFirst = async (
    isMobileDevice: boolean,
    paymentProvider: LocalPaymentProviders,
    buttonRef: any
  ) => {
    if (isMobileDevice) {
      setShowMobilePopupWarning(true);
      setHandleMobilePopupContinue(() => async () => {
        setShowMobilePopupWarning(false);
        setHandleMobilePopupContinue(null);
        await braintreeClientService.payWithLocalPaymentProvider(
          paymentProvider,
          buttonRef,
          getPaymentInfo(),
          paymentUIEvents,
          locale,
          layout.confirm_local_payment_button_text
        );
      });
    } else {
      await braintreeClientService.payWithLocalPaymentProvider(
        paymentProvider,
        buttonRef,
        getPaymentInfo(),
        paymentUIEvents,
        locale,
        layout.confirm_local_payment_button_text
      );
    }
  };

  const renderErrors = (errors: string[]) => {
    if (!errors) {
      return <></>;
    }
    return errors.map((item, index) => {
      return (
        <div className={classes.paymentErrorItem} key={index}>
          {layout[item] || item}
        </div>
      );
    });
  };

  const onCreditCardTypeChanged = (type: string) => {
    if (!type) {
      setCreditCardType(null);
    }

    switch (type) {
      case "visa": {
        setCreditCardType(faCcVisa);
        break;
      }
      case "master-card": {
        setCreditCardType(faCcMastercard);
        break;
      }
      case "american-express": {
        setCreditCardType(faCcAmex);
        break;
      }
      case "diners-club": {
        setCreditCardType(faCcDinersClub);
        break;
      }
      case "discover": {
        setCreditCardType(faCcDiscover);
        break;
      }
      case "jcb": {
        setCreditCardType(faCcJcb);
        break;
      }
    }
  };

  const handleClosePopup = () => {
    setCreditCardType(null);
    setCreditCardPaymentErrors([]);
    closePopup();
    setPaymentFormInitComplete(false);
    resetPaypal();
  };

  const clearCreditCardErrors = () => {
    setCardNumberErrorText("");
    setCvvErrorText("");
    setExpirationDateErrorText("");
    setCardholderNameErrorText("");
    setCreditCardPaymentErrors([]);
  };

  const clearPaypalErrors = () => {
    setPaypalErrors([]);
  };

  const clearAllErrors = () => {
    clearPaypalErrors();
    clearCreditCardErrors();
  };

  const handlePaypalError = (errorResult: any) => {
    console.log("handle paypal errors", errorResult);
    clearAllErrors();
    let errors = [];
    if (errorResult?.errorObject?.message) {
      setPaypalErrors([errorResult.errorObject.message]);
    } else {
      setPaypalErrors(["paypal_error"]);
    }
  };

  const handleGoogleError = (errorResult: any) => {
    setCreditCardPaymentErrors(["google_pay_error"]);
  };
  const handleApplePayError = (errorResult: any) => {
    setCreditCardPaymentErrors(["apple_pay_error"]);
  };
  const handleVenmoError = (errorResult: any) => {
    setCreditCardPaymentErrors(["venmo_error"]);
  };

  const handleThreeDSecureError = (errorResult: any) => {
    setCreditCardPaymentErrors(["three_d_secure_error"]);

    if (!errorResult.code) {
      return;
    }
    if (errorResult.code.indexOf("THREEDS_LOOKUP") === 0) {
      // an error occurred during the initial lookup request

      if (
        errorResult.code === "THREEDS_LOOKUP_TOKENIZED_CARD_NOT_FOUND_ERROR"
      ) {
        // either the passed payment method nonce does not exist
        // or it was already consumed before the lookup call was made
      } else if (errorResult.code.indexOf("THREEDS_LOOKUP_VALIDATION") === 0) {
        // a validation error occurred
        // likely some non-ascii characters were included in the billing
        // address given name or surname fields, or the cardholdername field
        // Instruct your user to check their data and try again
      } else {
        // an unknown lookup error occurred
      }
    } else {
      // some other kind of error
    }
  };

  const handleCreditCardError = (tokenizeErr: any) => {
    clearPaypalErrors();
    clearCreditCardErrors();

    clearAllErrors();

    let creditCardErrors: string[] = [];

    switch (tokenizeErr.code) {
      case "HOSTED_FIELDS_FIELDS_EMPTY":
        // occurs when none of the fields are filled in
        console.error("All fields are empty! Please fill out the form.");
        creditCardErrors.push(tokenizeErr.code.toLowerCase());
        break;
      case "HOSTED_FIELDS_FIELDS_INVALID":
        // occurs when certain fields do not pass client side validation
        console.error(
          "Some fields are invalid:",
          tokenizeErr.details.invalidFieldKeys
        );
        // you can also programmatically access the field containers for the invalid fields
        tokenizeErr.details.invalidFieldKeys.forEach((key) => {
          const errorKey = `credit_card_error_${key}`.toLowerCase();
          const error = layout[errorKey] || "";

          switch (key) {
            case "number": {
              setCardNumberErrorText(error);
              break;
            }
            case "cvv": {
              setCvvErrorText(error);
              break;
            }
            case "expirationDate": {
              setExpirationDateErrorText(error);
              break;
            }
            case "cardholderName": {
              setCardholderNameErrorText(error);
              break;
            }
          }

          let cssSelector = tokenizeErr.details.invalidFields[key];
        });
        break;
      case "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE":
        // occurs when:
        //   * the client token used for client authorization was generated
        //     with a customer ID and the fail on duplicate payment method
        //     option is set to true
        //   * the card being tokenized has previously been vaulted (with any customer)
        // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
        console.error("This payment method already exists in your vault.");
        creditCardErrors.push(tokenizeErr.code.toLowerCase());
        break;
      case "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED":
        // occurs when:
        //   * the client token used for client authorization was generated
        //     with a customer ID and the verify card option is set to true
        //     and you have credit card verification turned on in the Braintree
        //     control panel
        //   * the cvv does not pass verification (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
        // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
        console.error("CVV did not pass verification");
        creditCardErrors.push(tokenizeErr.code.toLowerCase());
        break;
      case "HOSTED_FIELDS_FAILED_TOKENIZATION":
        // occurs for any other tokenization error on the server
        console.error("Tokenization failed server side. Is the card valid?");
        creditCardErrors.push(tokenizeErr.code.toLowerCase());
        break;
      case "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR":
        // occurs when the Braintree gateway cannot be contacted
        console.error("Network error occurred when tokenizing.");
        creditCardErrors.push(tokenizeErr.code.toLowerCase());
        break;
      case "three_d_secure_error": {
        // occurs when a 3ds error happens
        console.error("three 3ds when tokenizing.");
        creditCardErrors.push(tokenizeErr.code.toLowerCase());
        break;
      }
      default: {
        if (tokenizeErr?.errorObject?.transaction?.gatewayRejectionReason) {
          switch (tokenizeErr.errorObject.transaction.gatewayRejectionReason) {
            case "three_d_secure": {
              creditCardErrors.push("three_d_secure_error");
              break;
            }
            default: {
              creditCardErrors.push("credit_card_generic_error");
              break;
            }
          }
        }
        else if (tokenizeErr?.errorObject?.message?.startsWith("Merchant account does not support 3D Secure transactions for card type")){
          creditCardErrors.push("three_d_secure_error");
        }
        else {
          creditCardErrors.push("credit_card_generic_error");
        }

        break;
      }
    }
    setCreditCardPaymentErrors(creditCardErrors);
  };

  const paymentUIEvents: IPaymentUIEvents = {
    showLoading: () => {
      setPaymentMethodsLoading(true);
      clearAllErrors();
    },
    hideLoading: () => {
      setPaymentMethodsLoading(false);
    },
    onCreditCardTypeChanged,
    onSubmit: async () => {
      setPaymentInProgress(true);
    },
    handleSuspectedDropOff: () => {
      Logger.logTrace(
        "Suspected drop off after successful local payment, closing popup"
      );
      handleClosePopup();
    },
    onError: (errorType: PaymentErrorType, errorResult: any) => {
      setPaymentInProgress(false);
      setPaymentMethodsLoading(false);

      switch (errorType) {
        case PaymentErrorType.Google: {
          handleGoogleError(errorResult);
          break;
        }

        case PaymentErrorType.ApplePay: {
          handleApplePayError(errorResult);
          break;
        }

        case PaymentErrorType.Venmo: {
          handleVenmoError(errorResult);
          break;
        }

        case PaymentErrorType.ThreeDS: {
          handleThreeDSecureError(errorResult);
          break;
        }
        case PaymentErrorType.Recaptcha: {
          console.log("***", errorResult);
          closePopup();
          setRecaptchaErrorCodes(errorResult.errorCodes);
          setShowRecaptchaErrorPopup(true);
          break;
        }
        case PaymentErrorType.CreditCard: {
          handleCreditCardError(errorResult);
          break;
        }
        case PaymentErrorType.Paypal: {
          handlePaypalError(errorResult);
          break;
        }
        case PaymentErrorType.Server: {
          setCreditCardPaymentErrors(["credit_card_generic_error"]);
          break;
        }
        case PaymentErrorType.Klarna: {
          setCreditCardPaymentErrors(["klarna_error"]);
          break;
        }
        case PaymentErrorType.iDeal: {
          setCreditCardPaymentErrors(["i_deal_error"]);
          break;
        }
        default: {
          console.log(
            "We are not handling this kind of error yet: ",
            errorType
          );
          console.error("The error: ", errorType);
        }
      }
    },
  };

  return (
    <>
      {showRecaptchaErrorPopup && recaptchaErrorCodes && (
        <RecaptchaErrorPopup
          errorCodes={recaptchaErrorCodes}
          openPopup={showRecaptchaErrorPopup}
          closePopup={() => {
            setRecaptchaErrorCodes(null);
            setShowRecaptchaErrorPopup(false);
          }}
        />
      )}

      <Dialog
        disableEnforceFocus={true} // this is important - without it, the three d secure OTP cannot be entered - see https://github.com/mui/material-ui/issues/17497
        aria-labelledby="simple-dialog-title"
        open={popupOpen}
        onClose={handleClosePopup}
        onRendered={async () => {
          clearAllErrors();

          try {
            setVerificiationUrl(
              braintreeClientService.getVerificationUrl(
                getPaymentInfo(),
                paymentUIEvents
              )
            );

            // very important to wait until the form has rendered before trying to init the braintree form otherwise we have a null reference
            await braintreeClientService.initPaymentForm(
              creditCardFormRef.current,
              getPaymentInfo,
              paymentUIEvents,
              {
                venmo: {
                  button: venmoButtonRef.current,
                  setShow: setShowVenmo,
                },
                applePay: {
                  button: applePayButtonRef.current,
                  setShow: setShowApplePay,
                },
                paypalButton: payPalButtonRef.current,
                ideal: {
                  button: iDealButtonRef.current,
                  setShow: setShowIdeal,
                },
                klarna: {
                  button: klarnaButtonRef.current,
                  setShow: setShowKlarna,
                },
              }
            );
            analyticsService.logAddPaymentInfo(getPaymentInfo());
            setPaymentFormInitComplete(true);
          } catch (error) {
            Logger.logError(error, {
              page: "donations",
              action: "init payment form",
            });
          }
        }}
      >
        <div className={classes.payNowPopupWrapper}>
          <Typography variant="h5" className={classes.modalTitle} style={{position:'relative', display:'flex', alignItems:'center',justifyContent:'center'}}>
            <IconButton
              style={{ left: 0, position: "absolute",padding:0 }}
              onClick={() => {
                goBackToAddressPopup();
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            {layout.credit_card_details_title}
          </Typography>
          <form
            ref={creditCardFormRef}
            id="credit-card-form"
            name="credit-card-form"
          >
            <Grid
              container
              alignItems="center"
              justify="center"
              spacing={3}
              className={classes.paynowGrid}
            >
              {/* Card Number, expiration dat and cvv MUST BE DIVS. 
              BRAINTREE WILL TURN THEM INTO INPUTS
              TODO - PRITAM NEEDS TO STYLE THEM TO LOOK LIKE OUR OTHER INPUTS */}
              {/* HERE ARE THE GENERIC CREDIT CARD ERRORS NOT ASSOCIATED WITH A PARTICULAR FIELD */}
              <Grid item xs={12}>
                {creditCardPaymentErrors &&
                  creditCardPaymentErrors.length > 0 && (
                    <Alert severity="error">
                      {renderErrors(creditCardPaymentErrors)}
                    </Alert>
                  )}
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.braintreeEntryField)}
                  id="card-number"
                >
                  {" "}
                  {creditCardType && (
                    <FontAwesomeIcon
                      style={{ maxWidth: 40 }}
                      size="lg"
                      className={classes.cardSize}
                      pull="right"
                      color="blue"
                      icon={creditCardType}
                    />
                  )}
                </div>
                {cardNumberErrorText && (
                  <div className={classes.fieldError}>
                    {cardNumberErrorText}
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <div
                  id="expiration-date"
                  className={classes.braintreeEntryField}
                ></div>
                {expirationDateErrorText && (
                  <div className={classes.fieldError}>
                    {expirationDateErrorText}
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <div id="cvv" className={classes.braintreeEntryField}></div>
                {cvvErrorText && (
                  <div className={classes.fieldError}>{cvvErrorText}</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <div
                  id="cardholder-name"
                  className={classes.braintreeEntryField}
                ></div>
                {cardholderNameErrorText && (
                  <div className={classes.fieldError}>
                    {cardholderNameErrorText}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} className={classes.totalAmt}>
                {netAmount &&
                  !recurringDonation &&
                  layout.total_single_payment_message &&
                  layout.total_single_payment_message.replace(
                    "{{paymentString}}",
                    `${
                      state.currency.symbol
                    } ${BraintreeConfig.getTotalAmountString(
                      netAmount,
                      coverTransactionCosts
                    )} ${state.currency.code}`
                  )}
                {netAmount &&
                  recurringDonation &&
                  layout.total_recurring_payment_message &&
                  layout.total_recurring_payment_message.replace(
                    "{{paymentString}}",
                    `${
                      state.currency.symbol
                    } ${BraintreeConfig.getTotalAmountString(
                      netAmount,
                      coverTransactionCosts
                    )} ${state.currency.code}`
                  )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={paymentInProgress}
                  color="primary"
                  fullWidth
                  className={classes.proceedBtn}
                  type="submit"
                >
                  {layout.pay_now_with_credit_card_button}
                </Button>
              </Grid>
            </Grid>
          </form>

          <div
            id="additional-payment-methods"
            style={{ display: paymentFormInitComplete ? "block" : "none" }}
          >
            <Typography variant="h5" className={classes.orWrapper}>
              {layout.or_divider}
            </Typography>

            <div
              id="paypal-button"
              className={classes.paypalButton}
              ref={payPalButtonRef}
              style={{ display: "none" }}
            ></div>

            <Button
              id="apple-pay"
              ref={applePayButtonRef}
              className={clsx(
                classes.applePayButton,
                showApplePay
                  ? classes.showPaymentButton
                  : classes.hidePaymentButton
              )} // this will get updated when the popup opens if apple pay is supported
              onClick={() =>
                braintreeClientService.payWithApple(
                  applePayButtonRef.current,
                  getPaymentInfo(),
                  paymentUIEvents,
                  layout.apple_pay_company_name_label
                )
              }
            >
              {layout.pay_with_prefix}{" "}
              <FontAwesomeIcon icon={faApplePay} size="lg" />
            </Button>
            <Button
              id="venmo"
              ref={venmoButtonRef}
              className={clsx(
                classes.venmoButton,
                showVenmo
                  ? classes.showPaymentButton
                  : classes.hidePaymentButton
              )} // this will get updated when the popup opens if venmo is supported
              onClick={() =>
                braintreeClientService.payWithVenmo(
                  venmoButtonRef.current,
                  getPaymentInfo(),
                  paymentUIEvents
                )
              }
            >
              <img src="/venmo.svg" />
            </Button>
            <Button
              id="klarna"
              ref={klarnaButtonRef}
              className={clsx(
                classes.klarnaPayButton,
                showKlarna
                  ? classes.showPaymentButton
                  : classes.hidePaymentButton
              )}
              onClick={async () => {
                await payLocalMaybeOpenMobileWarningFirst(
                  matchesAll,
                  LocalPaymentProviders.Klarna,
                  klarnaButtonRef.current
                );
              }}
            >
              {layout.pay_with_klarna}
            </Button>
            <Button
              id="iDeal"
              ref={iDealButtonRef}
              className={clsx(
                classes.iDealPayButton,
                showIdeal
                  ? classes.showPaymentButton
                  : classes.hidePaymentButton
              )}
              onClick={async () => {
                await payLocalMaybeOpenMobileWarningFirst(
                  matchesAll,
                  LocalPaymentProviders.iDeal,
                  iDealButtonRef.current
                );
              }}
            >
              {layout.pay_with_ideal}
            </Button>
            {/* START MOBILE POPUPS WARNING DIALOG */}
            <MobilePopupWarningPopup
              openPopup={showMobilePopupWarning}
              handleContinue={handleMobilePopupContinue}
              handleClosePopup={() => {
                setShowMobilePopupWarning(false);
                setHandleMobilePopupContinue(null);
              }}
            />

            {/* END MOBILE POPUPS WARNING DIALOG */}
          </div>
          {/* HERE ARE THE GENERIC PAYPAL ERRORS */}
          <Grid container alignItems="center" justify="center" spacing={3}>
            <Grid item xs={12}>
              <Grid item>
                {paypalErrors && paypalErrors.length > 0 && (
                  <Alert severity="error">{renderErrors(paypalErrors)}</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
          {!paymentMethodsLoading && verificationUrl && (
            <Grid
              style={{ paddingTop: 20 }}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <a href={verificationUrl} target="_blank">
                  <img
                    src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
                    style={{width:'100%', maxWidth: 350 }}
                  />
                </a>
              </Grid>
            </Grid>
          )}
          {(paymentMethodsLoading || paymentInProgress) && (
            <div className={classes.buttonProgress}>
              <CircularProgress size={64} />
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

PayNowPopup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(PayNowPopup);
