import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CustomLink from "../../components/CustomLink";
import VideoModal from "../../components/VideoModal";
import Image from "next/future/image";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import heroIcon1 from "../../public/aboutUsHero/homehero1.svg";
import heroIcon2 from "../../public/aboutUsHero/homehero2.svg";
import heroIcon3 from "../../public/aboutUsHero/homehero5.svg";
import heroIcon4 from "../../public/aboutUsHero/homehero8.svg";
import { motion } from "framer-motion";
import { vScaleFade, vTextFade } from "../../utils/animations";

const AboutHero = ({ slice, classes }) => {
  const { primary } = slice;
  const [openModal, setOpenModal] = useState(false);

  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };

  const viewport = {
    once: true,
    margin: "0%",
  };

  const vZoomInBounce = {
    offscreen: {
      scale: 0,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        delay: 0.4,
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const vZoomInBounceIcon = {
    offscreen: {
      scale: 0,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        delay: 0.2,
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  return (
    <div className={classes.outer}>
      <Container maxWidth="lg" className={classes.aboutHero}>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            duration: 0.8,
          }}
        >
          <Typography variant="h1" className={classes.heroTitle}>
            {RichText.asText(primary.title)}
          </Typography>
        </motion.div>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.2,
            duration: 0.8,
          }}
        >
          <Typography variant="subtitle1" className={classes.heroSubTitle}>
            {RichText.asText(primary.subtitle)}
          </Typography>
        </motion.div>
        <div className={classes.heroButton}>
          <CustomLink link={primary.primaryCtaButtonLink}>
            <motion.div
              variants={vScaleFade}
              initial={"offscreen"}
              animate={"onscreen"}
              viewport={viewport}
            >
              <Button variant="contained" color="primary">
                {RichText.asText(primary.primaryCtaButtonText)}
              </Button>
            </motion.div>
          </CustomLink>
          {!primary.secondaryButtonType ? (
            <CustomLink link={primary.secondaryCtaButtonLink}>
              <motion.div
                variants={vScaleFade}
                initial={"offscreen"}
                animate={"onscreen"}
                viewport={viewport}
              >
                <Button variant="contained" color="secondary">
                  {RichText.asText(primary.secondaryCtaButtonText)}
                </Button>
              </motion.div>
            </CustomLink>
          ) : (
            <motion.div
              variants={vScaleFade}
              initial={"offscreen"}
              animate={"onscreen"}
              viewport={viewport}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={openVideoDialog}
              >
                {RichText.asText(primary.secondaryCtaButtonText)}
              </Button>
            </motion.div>
          )}
        </div>
      </Container>
      <motion.div
        className="homehero1"
        variants={vZoomInBounceIcon}
        initial={"offscreen"}
        animate={"onscreen"}
        viewport={viewport}
      >
        <Image src={heroIcon1} alt={"Hero icon"} priority />
      </motion.div>
      <motion.div
        className="homehero2"
        variants={vZoomInBounceIcon}
        initial={"offscreen"}
        animate={"onscreen"}
        viewport={viewport}
      >
        <Image src={heroIcon2} alt={"Hero icon"} priority />
      </motion.div>
      {primary.pic1?.url && (
        <motion.div
          className="homehero3"
          variants={vZoomInBounce}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
        >
          <Image
            src={primary.pic1.url}
            alt={primary.pic1.alt || ""}
            width={primary.pic1.dimensions.width}
            height={primary.pic1.dimensions.height}
            priority
          />
        </motion.div>
      )}
      {primary.pic2?.url && (
        <motion.div
          className="homehero4"
          variants={vZoomInBounce}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
        >
          <Image
            src={primary.pic2.url}
            alt={primary.pic2.alt || ""}
            width={primary.pic2.dimensions.width}
            height={primary.pic2.dimensions.height}
            priority
          />
        </motion.div>
      )}
      <motion.div
        className="homehero5"
        variants={vZoomInBounceIcon}
        initial={"offscreen"}
        animate={"onscreen"}
        viewport={viewport}
      >
        <Image src={heroIcon3} alt={"Hero icon"} priority />
      </motion.div>
      {primary.pic3?.url && (
        <motion.div
          className="homehero6"
          variants={vZoomInBounce}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
        >
          <Image
            src={primary.pic3.url}
            alt={primary.pic3.alt || ""}
            width={primary.pic3.dimensions.width}
            height={primary.pic3.dimensions.height}
            priority
          />
        </motion.div>
      )}
      {primary.pic4?.url && (
        <motion.div
          className="homehero7"
          variants={vZoomInBounce}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
        >
          <Image
            src={primary.pic4.url}
            alt={primary.pic4.alt || ""}
            width={primary.pic4.dimensions.width}
            height={primary.pic4.dimensions.height}
            priority
          />
        </motion.div>
      )}
      <motion.div
        className="homehero8"
        variants={vZoomInBounceIcon}
        initial={"offscreen"}
        animate={"onscreen"}
        viewport={viewport}
      >
        <Image src={heroIcon4} alt={"Hero Icon"} priority />
      </motion.div>
      {primary.pic5?.url && (
        <motion.div
          className="homehero9"
          variants={vZoomInBounce}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
        >
          <Image
            src={primary.pic5.url}
            alt={primary.pic5.alt || ""}
            width={primary.pic5.dimensions.width}
            height={primary.pic5.dimensions.height}
            priority
          />
        </motion.div>
      )}
      {primary.pic6?.url && (
        <motion.div
          className="homehero10"
          variants={vZoomInBounce}
          initial={"offscreen"}
          animate={"onscreen"}
          viewport={viewport}
        >
          <Image
            src={primary.pic6.url}
            alt={primary.pic6.alt || ""}
            width={primary.pic6.dimensions.width}
            height={primary.pic6.dimensions.height}
            priority
          />
        </motion.div>
      )}
      <div className={classes.aboutHeroOverlay}></div>
      <VideoModal
        open={openModal}
        handleClose={closeVideoDialog}
        video={primary.videoUrl}
      />
    </div>
  );
};

AboutHero.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      backgroundImage: imagePropTypes,
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      primaryCtaButtonText: structuredTextPropTypes,
      primaryCtaButtonLink: structuredTextPropTypes,
      secondaryCtaButtonText: structuredTextPropTypes,
      secondaryCtaButtonLink: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(AboutHero);
