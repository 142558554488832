import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import { motion } from "framer-motion";
import { vSlideRightFade } from "../../utils/animations";

const ImpactStatement = ({ slice, classes }) => {
  const { primary } = slice;

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.impactStatement}>
      <Container maxWidth="md">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <motion.div
              variants={vSlideRightFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              <Typography
                variant="h1"
                component="h2"
                className="impactStatement"
              >
                {RichText.asText(primary.impactStatement)}
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

ImpactStatement.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      impact_statement: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(ImpactStatement);
