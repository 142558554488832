import Logger from '../error-logging-service';
import * as threeDSecure from 'braintree-web/three-d-secure'
import IPaymentFormInfo from '../../models/braintree/IPaymentFormInfo';
import { Address } from '../../models/braintree/CountryConfig';
import { getRegionCode } from '../../utils/regions';
import { BraintreeBranch } from '../../models/braintree/DonationModels';

export interface ThreeDSecureInstance {
    verifyCard: (options: any) => Promise<any>
}
export interface ThreeDSecureCreateResult {
    success: boolean
    tds?: ThreeDSecureInstance
    error?: any
}
export interface VerifyCardResult {
    nonce: string
    threeDSecureInfo: {
        liabilityShifted: boolean
        liabilityShiftPossible: boolean
        threeDSecureAuthenticationId: string
    }
}
export interface ThreeDSecureVerifyCardResult {
    success: boolean
    verifyCardResult?: VerifyCardResult
    error?: any
}

const ThreeDSecureService = {
    create: async (authToken: string): Promise<ThreeDSecureCreateResult> => {

        try {
            const tds = await threeDSecure
                .create({ authorization: authToken, version: 2 })

            return { success: true, tds }
        }
        catch (error) {
            Logger.logError(error)
            return { success: false, error }
        }
        finally {
            Logger.logTrace("Finished Creating TDS", null)
        }
    },
    //https://braintree.github.io/braintree-web/current/ThreeDSecure.html#verifyCard
    verifyCard: async(tds: ThreeDSecureInstance, totalAmount: string, tokenizationPayload: any, 
        paymentInfo: IPaymentFormInfo, branch: BraintreeBranch) : Promise<ThreeDSecureVerifyCardResult>=> {
        try {
            const verifyCardResult = await tds.verifyCard({
                onLookupComplete: (data, next) => {
                    Logger.logTrace("on lookup complete 3ds", {data});
                    next();
                },
                amount: totalAmount,
                nonce: tokenizationPayload.nonce,
                bin: tokenizationPayload.details.bin,
                email: paymentInfo.address.email,
                billingAddress: {
                    givenName: paymentInfo.address.firstName,
                    surname: paymentInfo.address.lastName,
                    phoneNumber: Address.getFullPhoneNumber(paymentInfo.address).replace(/[\(\)\s\-]/g, ''), // remove (), spaces, and - from phone number
                    streetAddress: paymentInfo.address.address1,
                    extendedAddress: paymentInfo.address.address2,
                    locality: paymentInfo.address.city,
                    region: getRegionCode(branch, paymentInfo.address.state),
                    postalCode: paymentInfo.address.zip,
                    countryCodeAlpha2: paymentInfo.address.country.code
                }
            })

            const info = verifyCardResult.threeDSecureInfo

            if ((info.liabilityShifted || !info.liabilityShiftPossible) && verifyCardResult.nonce){
                return {success: true, verifyCardResult}
            }
            return {success: false,error: {code: "-999",message: "could_not_verify"}}
            
        }
        catch (error){
            Logger.logError(error,{type: "3ds_verify"})
            return {success: false,error}
        }
        finally{
            Logger.logTrace("finished 3ds verify card call")
        }
    }
}

export default ThreeDSecureService