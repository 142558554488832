import pattern from "../../public/patternSimpleText.svg";

const styles = (theme) => ({
  aboutQuoteBg: {
    position: "relative",
    overflowX: "hidden",
    backgroundImage: `url(${pattern.src})`,
    backgroundSize: "110% 158%",
    backgroundPosition: "100%",
    backgroundColor: "#397DB7",
    backgroundRepeat: "no-repeat",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      backgroundPosition: "100%",
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      backgroundPosition: "100%",
      backgroundSize: "cover",
    },
    "& .quoteImg": {
      width: 26,
      height: "auto",
      marginRight: theme.spacing(1),
    },
    "& .quoteText": {
      fontWeight: 900,
      color: "#ffffff",
      fontStyle: "italic",
      lineHeight: 2.72,
      [theme.breakpoints.down("sm")]: {
        lineHeight: 1.72,
      },
    },
    "& .quoteAuthor": {
      fontSize: 21,
      fontWeight: 900,
      color: "#fff",
      marginTop: theme.spacing(7.5),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3.5),
      },
    },
    "& .quoteRight": {
      position: "absolute",
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    "& .rightImage": {
      borderRadius: "67% 40% 53% 60% / 40% 37% 63% 70%",
      height: 469,
      width: 570,
      objectFit: "cover",
      position: "relative",
      zIndex: 99,
      [theme.breakpoints.down("md")]: {
        width: 500,
      },
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "auto",
      },
    },
    "& .rightImageOverlay": {
      borderRadius: "32% 68% 42% 58% / 36% 71% 29% 64%",
      width: 570,
      height: 469,
      position: "absolute",
      zIndex: 9,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      [theme.breakpoints.down("md")]: {
        width: 500,
      },
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: 350,
        height: 232,
      },
      [theme.breakpoints.down("400")]: {
        width: 250,
        height: 170,
      },
    },
  },
});

export default styles;
