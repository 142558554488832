import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import PersonItem from "../../components/PersonItem";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useLayout } from "../../utils/layout-context";
import { Element } from "react-scroll";
import CustomLink from "../../components/CustomLink";
import { motion } from "framer-motion";
import {
  vPulseIn,
  vSlideRightFadeCustom,
  vTextFade,
} from "../../utils/animations";

const FeaturedMembers = ({ slice, classes }) => {
  const { primary, items } = slice;
  const { layout } = useLayout();
  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.featuredMember}>
      <Container maxWidth="lg">
        <Element name={primary.anchorId} className="element"></Element>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.5,
            duration: 0.8,
          }}
        >
          <TitleSubtitle
            title={RichText.asText(primary.title)}
            subtitle={RichText.asText(primary.subtitle)}
          />
        </motion.div>
        <Grid
          container
          spacing={4}
          className="boardMemberItem"
          direction="row"
          justify="center"
          alignItems="center"
        >
          {items &&
            items.map((item, idx) => {
              return (
                layout &&
                item?.person?.data?.person_type && (
                  <Grid item xs={12} sm={6} md={4} key={idx}>
                    <motion.div
                      variants={vSlideRightFadeCustom}
                      initial={"offscreen"}
                      whileInView={"onscreen"}
                      viewport={viewport}
                      transition={{
                        type: "tween",
                        delay: idx * 0.2,
                        duration: 0.8,
                      }}
                      style={{
                        height: "100%",
                      }}
                    >
                      <PersonItem
                        bannerImageSrc={item.person.data.portrait_image.url}
                        bannerImageAlt=""
                        name={RichText.asText(item.person.data.name)}
                        title={RichText.asText(item.person.data.title)}
                        btnLabel={RichText.asText(layout.full_bio_button_text)}
                        showTag={false}
                        showBtn
                        link={item.person}
                      />
                    </motion.div>
                  </Grid>
                )
              );
            })}
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <CustomLink link={primary.ctaButtonLink}>
            <motion.div
              variants={vPulseIn}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              <Button variant="contained" color="primary">
                {RichText.asText(primary.ctaButtonText)}
              </Button>
            </motion.div>
          </CustomLink>
        </Grid>
      </Container>
    </div>
  );
};

FeaturedMembers.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      cta_button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(FeaturedMembers);
