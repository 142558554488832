import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import Image from "next/future/image";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import quoteLeft from "../../public/quote-left-font-awesome.png";
import quoteRight from "../../public/quote-right-font-awesome.png";
import { vBounce, vSlideRightFade } from "../../utils/animations";
import { motion } from "framer-motion";

const AboutQuote = ({ slice, classes }) => {
  const { primary } = slice;

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.aboutQuoteBg}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} container direction="row" justify="center">
            <div className="rightImageOverlay" />
            {primary.leftImage?.url && (
              <motion.div
                variants={vBounce}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={viewport}
                style={{
                  zIndex: 10,
                }}
              >
                <Image
                  className="rightImage"
                  src={primary.leftImage.url}
                  alt={primary.leftImage.alt || ""}
                  width={primary.leftImage.dimensions.width}
                  height={primary.leftImage.dimensions.height}
                />
              </motion.div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              variants={vSlideRightFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              <Typography variant="subtitle1" className="quoteText">
                <Image src={quoteLeft} alt="quotes" className="quoteImg" />
                {RichText.asText(primary.quoteText)}
                <Image
                  src={quoteRight}
                  className="quoteRight quoteImg"
                  alt="quotes"
                />
              </Typography>
              <Typography className="quoteAuthor">
                {RichText.asText(primary.quoteAuthor)}
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

AboutQuote.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      leftImage: imagePropTypes,
      quoteText: structuredTextPropTypes,
      quoteAuthor: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(AboutQuote);
