import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-scroll";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import Image from "next/future/image";
import { motion } from "framer-motion";
import { vPulseIn, vTextFade } from "../../utils/animations";

const WhoWeAre = ({ slice, classes }) => {
  const { primary, items } = slice;

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.whoWeAre}>
      <Container maxWidth="lg">
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.5,
            duration: 0.8,
          }}
        >
          <TitleSubtitle
            title={RichText.asText(primary.title)}
            subtitle={RichText.asText(primary.subtitle)}
            showClass
          />
        </motion.div>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
          className="whoWeAreContainer"
        >
          {items.map((item) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              container
              direction="column"
              justify="center"
              alignItems="center"
              className="testimonialCardGrid"
            >
              <motion.div
                variants={vPulseIn}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={viewport}
              >
                <Link to={item.anchorId} smooth={true} offset={-80}>
                  {item.icon?.url && (
                    <Image
                      src={item.icon.url}
                      alt={item.icon.alt}
                      className="icon"
                      width={item.icon.dimensions.width}
                      height={item.icon.dimensions.height}
                    />
                  )}
                  <Typography variant="h6" className="itemTitle">
                    {RichText.asText(item.title)}
                  </Typography>
                  <Typography variant="subtitle2" className="anchorLinkText">
                    {RichText.asText(item.anchorLinkText)}
                  </Typography>
                </Link>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

WhoWeAre.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: imagePropTypes,
        title: PropTypes.string,
        anchor_link_text: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(WhoWeAre);
