import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import VideoModal from "../../components/VideoModal";
import { motion } from "framer-motion";
import Image from "next/future/image";
import quoteLeftIcon from "../../public/quote-left-font-awesome.png";
import quoteRightIcon from "../../public/quote-right-font-awesome.png";
import {
  vBounce,
  vPulseIn,
  vSlideLeftFade,
  vSlideRightFadeCustom,
  vTextFade,
} from "../../utils/animations";

const QuoteTestimonials = ({ slice, classes }) => {
  const { primary, items } = slice;
  const [openModal, setOpenModal] = useState(false);

  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.quoteTestimonialsBg}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
          className="quoteWrapper"
        >
          <Grid item xs={12} md={6}>
            <motion.div
              variants={vSlideLeftFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              <Typography variant="subtitle1" className="quoteText">
                <Image
                  src={quoteLeftIcon}
                  alt="quotes"
                  className="quoteImg"
                  priority
                />
                {RichText.asText(primary.quoteText)}
                <Image
                  src={quoteRightIcon}
                  className="quoteRight quoteImg"
                  alt="quotes"
                  priority
                />
              </Typography>
            </motion.div>
            <motion.div
              variants={vTextFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
              transition={{
                type: "tween",
                delay: 0.5,
                duration: 0.8,
              }}
            >
              <Typography className="quoteAuthor">
                {RichText.asText(primary.quoteAuthor)}
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6} container direction="row" justify="center">
            <div className="rightImageOverlay" />
            {primary.rightImage?.url && (
              <motion.div
                variants={vBounce}
                initial={"offscreen"}
                animate={"onscreen"}
                viewport={viewport}
                style={{
                  zIndex: 10,
                }}
              >
                <Image
                  className="rightImage"
                  src={primary.rightImage.url}
                  alt={primary.rightImage.alt || ""}
                  width={primary.rightImage.dimensions.width}
                  height={primary.rightImage.dimensions.height}
                  sizes={"100vw"}
                />
              </motion.div>
            )}
          </Grid>
        </Grid>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            duration: 0.8,
          }}
        >
          <TitleSubtitle
            title={RichText.asText(primary.title)}
            subtitle={RichText.asText(primary.subtitle)}
            showClass
          />
        </motion.div>

        <Grid container spacing={4} className="testimonialWrapper">
          {items.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <motion.div
                className={classes.testimonialCardGrid}
                variants={vSlideRightFadeCustom}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={viewport}
                transition={{
                  type: "tween",
                  delay: index * 0.2,
                  duration: 0.8,
                }}
              >
                {item.profilePicture?.url && (
                  <Image
                    className={classes.testimonialImg}
                    src={item.profilePicture.url}
                    alt={item.profilePicture.alt || ""}
                    width={item.profilePicture.dimensions.width}
                    height={item.profilePicture.dimensions.height}
                    priority
                  />
                )}
                <div className={classes.testimonialCard}>
                  <Typography variant="subtitle1" className="name">
                    {RichText.asText(item.name)}
                  </Typography>
                  <div className="testimonialTitle">
                    {RichText.asText(item.title)}
                  </div>
                  <Typography variant="subtitle2" className="testimonial">
                    {RichText.asText(item.testimonial)}
                  </Typography>
                </div>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <motion.div
            variants={vPulseIn}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={viewport}
          >
            <Button
              variant="outlined"
              className={classes.videoButton}
              onClick={openVideoDialog}
            >
              {RichText.asText(primary.videoButtonText)}
            </Button>
          </motion.div>
        </Grid>
      </Container>
      <VideoModal
        open={openModal}
        handleClose={closeVideoDialog}
        video={primary.vimeoLink}
      />
    </div>
  );
};

QuoteTestimonials.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      quoteText: structuredTextPropTypes,
      quoteAuthor: structuredTextPropTypes,
      rightImage: imagePropTypes,
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      videoButtonText: structuredTextPropTypes,
      vimeoLink: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        profilePicture: imagePropTypes,
        name: structuredTextPropTypes,
        title: structuredTextPropTypes,
        testimonial: structuredTextPropTypes,
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(QuoteTestimonials);
